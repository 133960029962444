<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/zc/banner.png">
    </div>
    <div class="res" style="height: 569px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg2.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">企业资产现状 <br/>废旧IT设备成为企业的负担</div>
        <div class="betflex" style="height: 170px;margin-top: 109px">
          <div v-for="(item,idx) in twoArr" :key="idx" class="res zc-two">
            <span style="margin-bottom: 40px">{{item.tit}}</span>
            <div class="abs zc-two-img">
              <img style="width: 100%;height: 100%" :src="item.icon">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 989px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg3.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">企修通专业资产处置解决方案
        </div>
        <div style="height: 569px;margin-top: 82px;display: flex;flex-direction: column;align-items: flex-end">
          <div v-for="(item,index) in threeArr" :key="'t'+index" class="zc-three betflex">
            <div class="zc-three-img"></div>
            <div class="zc-three-v">
              <div class="zc-three-tit">{{item.tit}}</div>
              <div class="zc-three-desc">{{item.t1}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 284px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg4.png">
      <div class="abs home-p-com cflex" style="flex-direction: column;color: white">
        <div style="text-align: center;font-weight: 600;font-size: 40px;line-height: 65px;margin-bottom: 20px">
          企修通专业资产处置，为企业资产减压<br/>将您的废旧IT设备资产实现价值最大化
        </div>
        <div class="e-btn" @click="getMore">了解更多</div>
      </div>
    </div>
    <div class="res" style="height: 917px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg5.png">
      <div class="abs home-p-com" style="width: 1277px;">
        <div class="com-page-t1">案例分享
        </div>
        <div class="s-con-v" style="height: 645px">
          <div style="display: flex;height: 342px;margin:60px 39px 2px 38px">
            <div style="flex: 1">
              <div class="s-con-top">河北秦皇岛某事业单位</div>
              <div style="display: flex;margin-top: 50px">
                <img style="width: 215px;height: 214px" src="@/assets/zc/build.png">
                <div
                    style="font-size: 18px;font-weight: 400;color: #717477;line-height: 28px;margin: 0 28px;width: 348px;height: 214px;display: flex;flex-direction: column;justify-content: space-between">
                  <div><span style="color: #171717; font-weight: bold">公司概况：</span><br/>
                    该事业单位下辖<span class="al-color">7</span>家分行、<span class="al-color">35</span>家支行<br/>
                    共<span class="al-color">1700</span>余个营业机构<br/>
                    并发起设立1家金融租赁公司<br/>
                    <span class="al-color">1</span>家理财公司<br/>
                    <span class="al-color">12</span>家村镇银行<br/>
                    从业人员1.5万人
                  </div>

                </div>
              </div>
            </div>
            <img style="width: 575px;height: 324px" src="@/assets/zc/al.png">
          </div>
          <span class="s-question">面临的问题：</span>
          <div style="margin: 15px 35px 0 33px;">
            <div v-for="(item,k) in sevenArr" :key="k" class="s-q-bg">
              <img style="float:left;width: 30px;height: 25px;margin: 20px" src="@/assets/solution/ofs-i-chose-dui.png">
              <div style="display: flex;margin: 20px 20px 20px 70px">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 667px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg6.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">解决方案</div>
        <div class="betflex" style="height: 342px;margin-top: 80px">
          <div v-for="(item,index) in eighthArr" :key="'e'+index" class="zc-six-bg">
            <img style="width: 100%;height: 230px" class="comimg" :src="item.icon">
            <div class="zc-six-des" style="margin-top: 20px">{{item.tit}}</div>
            <div class="zc-six-des">{{item.des}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 960px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg7.png">
      <div class="abs home-p-com">
        <div class="com-page-t1" style="margin-bottom: 80px">资质证书&报告</div>
      </div>
    </div>
    <div class="res" style="height: 711px">
      <img class="comimg" style="width: 100%" src="@/assets/zc/bg8.png">
      <div class="abs home-p-com">
        <div class="com-page-t1" style="margin-bottom: 80px">来自客户的评价</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zcSolution",
  data () {
    return {
      twoArr: [
        {
          icon: require('@/assets/zc/ofs-i-xiaolv.png'),
          tit: '严重影响企业运营效率?',
        },
        {
          icon: require('@/assets/zc/ofs-i-chengben.png'),
          tit: '企业废旧设备多，管理成本高?',
        },
        {
          icon: require('@/assets/zc/ofs-i-anquan.png'),
          tit: '涉及数据安全，无法随意处置?',
        },
      ],
      threeArr: [
        {
          tit: '核心回收品类',
          t1: '笔记本、台式机、服务器、手机、平板、显示器、一体机等IT办公电子设备',
        },
        {
          tit: '非核心回收品类',
          t1: '打印机、投影仪、交换机、防火墙、监控等30余个品类',
        },
      ],
      sevenArr: [
        '回收的渠道少，接触到的回收商多是街边小店的废品回收商，价格优势低',
        'IT设备存储重要数据及资料，涉及数据安全问题，导致废弃设备大量堆积，缺乏安全的资产处置渠道',
      ],
      eighthArr: [
        {
          icon: require('@/assets/zc/pro2.png'),
          tit: '上门清点客户设备',
          des: '统计报废的设备型号'
        },
        {
          icon: require('@/assets/zc/pro.png'),
          tit: '上传到企修通平台',
          des: '进行多方报价'
        },
        {
          icon: require('@/assets/zc/pro1.png'),
          tit: '报价成功,上门收取设备，邮寄到',
          des: '回收商，重要数据安全销毁'
        },
      ]
    }
  },
  mounted () {
    this.$parent.gotoTop()
  },
  methods: {
    getMore () {
      this.$router.push('/free')
    }
  }
}
</script>
<style scoped>
  .zc-two {
    width: 380px;
    height: 170px;
    background: linear-gradient(180deg, #F9FCFF 0%, #ECF3FF 100%);
    box-shadow: 0px 3px 10px 1px rgba(31, 95, 255, 0.1);
    border-radius: 80px 18px 18px 18px;
    border: 2px solid #FFFFFF;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
  }

  .zc-two:hover {
    color: #1F5FFF;
  }

  .zc-two-img {
    left: 22px;
    top: -50px;
    width: 88px;
    height: 111px;
  }

  .zc-three {
    width: 509px;
    height: 240px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .zc-three-img {
    width: 96px;
    height: 203px;
    background-image: url("../../assets/zc/多边形 37.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 35px;
  }

  .zc-three:hover .zc-three-img {
    background-image: url("../../assets/zc/多边形 39.png");
  }

  .zc-three-tit {
    font-size: 25px;
    font-weight: 600;
    color: #292D33;
    line-height: 29px;
    margin: 40px 40px 30px 55px;
    position: relative;
  }

  .zc-three-tit:before {
    position: absolute;
    content: '';
    left: -25px;
    top: 4px;
    width: 6px;
    height: 22px;
    background: #1F5FFF;
  }

  .zc-three-desc {
    font-weight: 400;
    color: #606266;
    line-height: 32px;
    font-size: 22px;
    margin: 0 30px;
  }

  .zc-three-v {
    width: 387px;
    height: 240px;
    background: linear-gradient(180deg, #FFFFFF 0%, #EBF2FF 100%);;
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    border: 2px solid #FFFFFF;
  }

  .zc-three:hover .zc-three-v {
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    color: #ffffff;
  }

  .zc-three:hover .zc-three-tit {
    color: white;
  }

  .zc-three:hover .zc-three-tit:before {
    background: white;
  }

  .zc-three:hover .zc-three-desc {
    color: #E7EEFA;
  }

  .s-con-v {
    margin-top: 80px;
    height: 780px;
    background: linear-gradient(360deg, #E7EEFA 0%, #FFFFFF 100%);
    border-radius: 18px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
  }

  .s-con-top {
    font-size: 36px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 48px;
  }

  .s-question {
    width: 172px;
    height: 52px;
    line-height: 52px;
    background: rgba(31, 95, 255, 0.11);
    border-radius: 4px 4px 4px 4px;
    font-weight: 600;
    color: #1F5FFF;
    font-size: 22px;
    padding: 11px 20px;
    margin-left: 39px;
  }

  .s-q-bg {
    display: inline-block;
    background: rgba(255, 255, 255, 0.63);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 400;
    color: #171717;
    vertical-align: top;
    width: calc(50% - 12px - 2px);
    margin: 5px;
    border: 1px solid #FFFFFF;
  }

  .s-q-bg:hover {
    color: white;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border: 1px solid #FFFFFF;
  }

  .al-color {
    color: #1F5FFF;
    font-size: 22px;
    font-weight: bold;
  }

  .zc-six-bg {
    width: 387px;
    height: 342px;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px 1px rgba(31, 95, 255, 0.1);
    border-radius: 20px 21px 18px 18px;
    border: 2px solid #FFFFFF;
    display: flex;
    flex-direction: column;
    color: #171717;
    cursor: pointer;
  }

  .zc-six-bg:hover {
    background: #1F5FFF;
    box-shadow: 0px 10px 10px 1px rgba(31, 95, 255, 0.1);
    color: white;
  }

  .zc-six-des {
    font-weight: 400;
    line-height: 32px;
    font-size: 22px;
    text-align: center;
  }
</style>
